import { ActivatedRouteSnapshot, Router } from "@angular/router"
import { inject, Injectable } from "@angular/core"
import { toObservable } from '@angular/core/rxjs-interop'
import { Observable, of } from "rxjs"
import { catchError, filter, switchMap } from "rxjs/operators"
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { MasterDataCacheFacade } from '@appShared/services/master-data-cache.facade'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

@Injectable({
   providedIn: 'root'
})
export class SalesSchoolRegistrationGuardService {
   private _masterDataCacheFacade = inject(MasterDataCacheFacade)
   private _appFacade = inject(AppFacade)
   private _commonService = inject(CommonService)
   private _router = inject(Router)

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      const currentProfile = this._appFacade.currentProfile()
      const dealerInfo = currentProfile?.dealerInfo
      const currentDssId = dealerInfo?.dealerSalesSessionId
      const canProceed =
         currentDssId || (currentProfile && currentProfile.canViewDealerSearch)

      if (!canProceed) {
         this._commonService.pageNotAuthorized()
         this._router.navigate([SITE_DIRECTORY_ROUTES.uri])

         return of(false)
      }

      return toObservable(this._masterDataCacheFacade.colleges).pipe(
         filter(data => !!data), //filter till has data
         switchMap(() => of(true)),
         catchError(() => of(false))
      )
   }
}
