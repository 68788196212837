<div class="site-container">

   <app-nav-menu></app-nav-menu>

   <main role="main" class="container pt-3">

      <router-outlet></router-outlet>

   </main>

   <app-site-footer></app-site-footer>

</div>
