import { Routes, mapToCanActivate } from '@angular/router'
import { AuthGuardService } from '@appShared/services/auth-guard.service'
import { AccountGuardService } from '@account/shared/account-guard.service'
import { SalesSchoolRegistrationGuardService } from '@ssRegistrationShared/sales-school-registration-guard.service'
import { MerchandiseReturnsAuthGuardService } from '@merchandiseReturnsShared/merchandise-returns-auth-guard.service'
import { AppDashboardComponent } from '@appComponents/app-dashboard/app-dashboard.component'
import { SetDealerComponent } from '@appShared/components/set-dealer/set-dealer.component'
import { PageNotFoundComponent } from '@appComponents/page-not-found.component'
import { SITE_DIRECTORY_ROUTES } from '@appShared/site-directory.routes'

export const APP_ROUTES: Routes = [
   {
      path: 'home',
      component: AppDashboardComponent,
      canActivate: mapToCanActivate([AuthGuardService])
   },
   {
      path: SITE_DIRECTORY_ROUTES.set_dealer.baseUri,
      component: SetDealerComponent
   },
   //...ACCOUNT_ROUTES, /* If NOT lazy-loading */
   {
      path: SITE_DIRECTORY_ROUTES.account.baseUri,
      canActivate: mapToCanActivate([AccountGuardService]),
      loadChildren: () =>
         import('@account/account.routes').then(
            routes => routes.ACCOUNT_ROUTES
         )
   },
   //...MERCHANDISE_RETURNS_ROUTES, /* If NOT lazy-loading */
   {
      path: SITE_DIRECTORY_ROUTES.merchandise_returns.baseUri,
      canActivate: mapToCanActivate([MerchandiseReturnsAuthGuardService]),
      loadChildren: () =>
         import('@merchandiseReturns/merchandise-returns.routes').then(
            routes => routes.MERCHANDISE_RETURNS_ROUTES
         )
   },
   {
      path: SITE_DIRECTORY_ROUTES.sales_school_registration.baseUri,
      /*https://stackoverflow.com/questions/75564717/angulars-canactivate-interface-is-deprecated-how-to-replace-it#:~:text=An%20injectable%20class%20can%20be,the%20child%20class%20is%20deprecated.*/
      canActivate: mapToCanActivate([SalesSchoolRegistrationGuardService]),
      loadComponent: () =>
         import('@app/sales-school-registration/sales-school-registration.component').then(
            comp => comp.SalesSchoolRegistrationComponent
         )
   },
   {
      path: '404',
      component: PageNotFoundComponent
   },
   { path: '', redirectTo: 'home', pathMatch: 'full' },
   { path: '**', redirectTo: '404', pathMatch: 'full' }
]
