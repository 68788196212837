import { ChangeDetectionStrategy, Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { NavMenuComponent } from '@appComponents/nav-menu/nav-menu.component'
import { SiteFooterComponent } from '@appComponents/site-footer/site-footer.component'

@Component({
   selector: 'app-root',
   standalone: true,
   imports: [RouterOutlet, NavMenuComponent, SiteFooterComponent],
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.less'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
   title = 'advantage-4-dealers'
}
