import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { AsyncPipe, NgIf } from '@angular/common'
import { RouterLink } from '@angular/router'
import { toObservable } from '@angular/core/rxjs-interop'
import { of, switchMap } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'
import { SITE_DIRECTORY_ROUTES, RouteModel } from '@appShared/site-directory.routes'
import { GEN2_ROUTES } from '@appShared/gen2.routes'

@Component({
   selector: 'app-dashboard',
   templateUrl: './app-dashboard.component.html',
   styleUrls: ['./app-dashboard.component.less'],
   standalone: true,
   imports: [NgIf, RouterLink, AsyncPipe],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDashboardComponent {
   private _routes = SITE_DIRECTORY_ROUTES
   salesSchoolRegistrationUri = this._routes.sales_school_registration.uri
   remitOnlineUri = this._routes.account.remittance.uri
   merchandiseReturnsUri = this._routes.merchandise_returns.uri
   manageAccountUri = this._routes.account.manage.uri

   private _appFacade = inject(AppFacade)
   currentProfile = this._appFacade.currentProfile
   salesPortalUri = this._appFacade.salesPortalUri
   dealerTrainingUri = this._appFacade.dealerTrainingUri
   clickUpUri = this._appFacade.clickUpUri
   mediaResourceUri = this._appFacade.mediaResourceCenterUri
   teamHubUri = this._appFacade.teamHubUri

   private _dealerService = inject(DealerService)
   selectedDealer = this._dealerService.selectedDealer
   canAccessSSRegistration = this._dealerService.canAccessSSRegistration
   canViewOrgReporting = toObservable(this._dealerService.selectedDealer).pipe(
      switchMap(dealer => {
         if (!dealer?.dealerInfo) {
            return of(false)
         }

         return this._dealerService.getManagerialScope(dealer.dealerInfo.dealerSalesSessionId).pipe(
            switchMap(dealerScopes => {
               if (!dealerScopes) {
                  return of(false)
               }

               return of(!!(dealerScopes['isOrgLeader']) ||
                         !!(dealerScopes['isOrgAssist']) ||
                         !!(dealerScopes['isManager']) ||
                         !!(dealerScopes['isDSM']))
            })
         )
      })
   )

   gen2Routes = GEN2_ROUTES

   /*
    public methods
   */

   goToPortal(route?: RouteModel): void {
      this._appFacade.navigateToGen2(route?.uri)
   }
}
